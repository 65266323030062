.main {
  margin-right: 32px;
  background: #efefef;
  border: 1px solid #dadada;
  border-radius: 6px;
  width: 320px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.heading {
  padding: 16px;
  font-size: 24px;
  color: #222222;
  font-weight: 700;
}

.wrapper {
  height: 100%;
}

.container {
  padding: 16px;
  overflow-y: auto;
  height: 100%;
}

.isDragging {
  background: #e1ebee;
}
