.main {
  display: flex;
  align-items: center;
  height: var(--header-height);
  background: #4364af;
  padding: 0 32px;
}

.title {
  font-size: 24px;
  line-height: 32px;
  margin: 0;
  padding: 0;
  color: #fff;
}
