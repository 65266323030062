.container {
  user-select: none;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-bottom: 16px;
  min-height: 112px;
  padding: 12px;
}

.isDragging {
  background-color: #f0f8ff;
}

.heading {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: #4364af;
  font-weight: 700;
}

.avatar {
  border-radius: 12px;
  border: 1px solid #dadada;
  vertical-align: bottom;
}

.separation {
  border: 1px dashed #dedede;
  margin: 12px 0;
}

.content {
  font-size: 14px;
  color: #000000;
}
